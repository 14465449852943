var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
export var initialState = {
    loading: false,
    dialogs: [],
    isOverlayLoading: false,
    overlayLoadingText: ""
};
export var adminReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    console.log("Redux state change: ", action, state);
    switch (action.type) {
        case "SHOW_LOADING":
            return __assign(__assign({}, state), { loading: true });
        case "HIDE_LOADING":
            return __assign(__assign({}, state), { loading: false });
        case "SHOW_DIALOG": {
            var dialogs = state.dialogs.slice();
            var id = action.dialog.props.id || new Date().getTime();
            dialogs.push(React.cloneElement(action.dialog, { key: id, id: id }));
            return __assign(__assign({}, state), { dialogs: dialogs });
        }
        case "CLOSE_DIALOG": {
            var dialogs = state.dialogs;
            dialogs = dialogs.filter(function (x) { return x.props.id != action.id; });
            return __assign(__assign({}, state), { dialogs: dialogs });
        }
        case "GENERIC":
            return __assign(__assign({}, state), action.parameters);
        default:
            return state;
    }
};
