import store from "./redux_helpers/store";
import { closeDialogAction, hideLoadingAction, showDialogAction, showLoadingAction } from "./redux_helpers/actions";
import * as React from "react";
export function renderDialogsAndLoading() {
    var dialogs = store.getState().dialogs;
    return React.createElement(React.Fragment, null,
        dialogs,
        store.getState().loading && React.createElement("div", { className: "loading" },
            React.createElement("div", { className: "spinner" })));
}
export function showDialog(dialog) {
    store.dispatch(showDialogAction(dialog));
}
export function closeDialog(id) {
    store.dispatch(closeDialogAction(id));
}
export function showLoading() {
    store.dispatch(showLoadingAction());
}
export function hideLoading() {
    store.dispatch(hideLoadingAction());
}
