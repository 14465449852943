import axios from 'axios';
import snakeToCamel, { baseUrl, camelToSnake } from "../utils";
var instance = axios.create({
    baseURL: baseUrl
});
instance.interceptors.response.use(function (response) {
    return snakeToCamel(response);
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});
instance.interceptors.request.use(function (request) {
    request.data = camelToSnake(request.data);
    return request;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});
export default instance;
