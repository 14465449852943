export var showLoadingAction = function () {
    return {
        type: 'SHOW_LOADING'
    };
};
export var hideLoadingAction = function () {
    return {
        type: 'HIDE_LOADING'
    };
};
export var loadingTextAction = function (text) {
    return {
        type: 'SET_OVERLAY_LOADING_TEXT',
        text: text
    };
};
export var showDialogAction = function (dialog) {
    return {
        type: "SHOW_DIALOG",
        dialog: dialog
    };
};
export var closeDialogAction = function (id) {
    return {
        type: "CLOSE_DIALOG",
        id: id
    };
};
export var genericAction = function (parameters) {
    return {
        type: "GENERIC",
        parameters: parameters
    };
};
