export var baseUrl = document.getElementById("viewbag").dataset.baseUrl + "/";
export default function snakeToCamel(o) {
    var newO = {}, origKey, newKey, value;
    if (o instanceof Array) {
        newO = [];
        for (origKey in o) {
            value = o[origKey];
            if (typeof value === "object") {
                value = snakeToCamel(value);
            }
            newO.push(value);
        }
    }
    else {
        newO = {};
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = origKey.replace(/_([a-z])/g, function (g) {
                    return g[1].toUpperCase();
                });
                value = o[origKey];
                if (value)
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = snakeToCamel(value);
                    }
                newO[newKey] = value;
            }
        }
    }
    return newO;
}
export function camelToSnake(o) {
    var newO = {}, origKey, newKey, value;
    if (o instanceof Array) {
        newO = [];
        for (origKey in o) {
            value = o[origKey];
            if (typeof value === "object") {
                value = camelToSnake(value);
            }
            newO.push(value);
        }
    }
    else {
        newO = {};
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = origKey.replace(/\.?([A-Z]+)/g, function (x, y) {
                    return "_" + y.toLowerCase();
                }).replace(/^_/, "");
                value = o[origKey];
                if (value)
                    if (value && value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = camelToSnake(value);
                    }
                newO[newKey] = value;
            }
        }
    }
    return newO;
}
export function url(relative) {
    return baseUrl + relative;
}
export function cutString(str, chars) {
    return str.length <= chars ? str : str.substr(0, chars) + "...";
}
export function padLeft(str, width, char) {
    var newStr = str.toString();
    for (var i = 0; i < width - newStr.length; i++)
        newStr = char + newStr;
    return newStr;
}
export function padRight(str, width, char) {
    var newStr = str.toString();
    for (var i = 0; i < width - newStr.length; i++)
        newStr = newStr + char;
    return newStr;
}
export function format(str) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return args.reduce(function (prev, current, idx) { return prev.replace("{" + idx + "}", current); }, str);
}
export function hex2rgb(c) {
    var c = c.substring(1); // strip #
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue
    return { r: r, g: g, b: b };
}
var T = /** @class */ (function () {
    function T() {
    }
    T._ = function (str, domain) {
        if (domain === void 0) { domain = "Main"; }
        return str; //(window as any).gt.dgettext(domain, str);
    };
    return T;
}());
export { T };
